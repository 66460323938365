import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Home/OurStory.css";
import "../OurTeam.css";

const OurTeam = () => {
  return (
    <div className="our-story our-team">
      <div className="overlay">
        <div className="content">
          <h1>Our Team</h1>
          <p>
            At Exotic India Vacations, our team is made up of dedicated
            professionals with diverse expertise. We specialize in crafting
            exceptional travel experiences throughout India, Nepal, Bhutan, and
            Sri Lanka. From managing operations and creating custom itineraries
            to customer relations and cultural insights, each member plays a
            vital role in ensuring smooth journeys that immerse travelers in the
            region's rich heritage and natural splendor. Together, we aim to
            provide personalized experiences that surpass expectations, creating
            lasting memories and meaningful connections with the destinations we
            proudly present.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
