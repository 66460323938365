import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import OurStory from "../components/Home/OurStory";
import Slider from "react-slick";
import India from "../img/home/india.webp";
import IMAGES from "../img/image";
import OurTeam from "../components/About/OurTeam";
import "./About.css";

const About = () => {
  const [tab, setTab] = useState("Our Story");
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const arrowRef = useRef(null);
  const OurStoryRef = useRef(null);
  const valuesMissionRef = useRef(null);
  const whyUsRef = useRef(null);
  const ourTeamRef = useRef(null);

  const handleClick = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const whyUsArray = [
    {
      icon: IMAGES.ab1,
      heading: "Unmatched Knowledge",
      desc: "Our committed market specialists are enthusiastic about designing captivating and intriguing tours and adventures that will enchant travelers with local flavor and cater to their specific interests. Additionally, we possess in-house multilingual proficiency in German, French, and Spanish.",
    },
    {
      icon: IMAGES.ab2,
      heading: "Devoted Product Team",
      desc: "Our team is dedicated to experiential travel, which we deliver through unique experiences and offer all the tools needed to create product differentiation for your inquisitive travelers. From fun and fascinating adventures to video itineraries paired with recommended and curated information on hotels, cities, and destinations, we provide it all.",
    },
    {
      icon: IMAGES.ab3,
      heading: "Journey with Intention",
      desc: "We promote and support responsible tourism through various projects and initiatives directly tied to our core business values. At Exotic India Vacations, we are committed to environmental sustainability, maintaining a no-plastic policy and a paperless initiative.",
    },
    {
      icon: IMAGES.ab4,
      heading: "A Remarkable 24/7 Support Team",
      desc: "24/7 assistance available nationwide. Exceptional support underpins everything we do. Our team and local partners consistently deliver before, during, and after your journey. We are with you every step of the way.",
    },
    {
      icon: IMAGES.ab5,
      heading: "Unparalleled Value",
      desc: "Our enduring partnerships with hotels and suppliers guarantee that our clients receive exceptional benefits through our superior purchasing power.",
    },
    // {
    //   icon: IMAGES.ab6,
    //   heading: "Technology & XML Solutions",
    //   desc: "We utilize technology to provide a seamless and hassle-free experience for our partners. Our in-house developed system, “Tour Register,” addresses the current demand for XML API integration with real-time inventory management.",
    // },
    {
      icon: IMAGES.ab7,
      heading: "Marketing Assistance & Product Training",
      desc: "Our team's ongoing efforts and market expertise keep them informed about the latest trends and what's best suited for your unique needs. This includes thorough support with your brochure, content, high-resolution images, supplier coordination, and collaborative campaigns.",
    },
    // {
    //   icon: IMAGES.ab8,
    //   heading: "GDPR Adherence",
    //   desc: "Our company adheres to GDPR requirements for data privacy and security.",
    // },
    // {
    //   icon: IMAGES.ab9,
    //   heading: "Public Liability Coverage",
    //   desc: "We recognize how essential it is for our guests to feel safe and at ease while traveling. Ensuring our guest's safety is always our top priority.",
    // },
    {
      icon: IMAGES.ab10,
      heading: "Emergency Response Team",
      desc: "We have a robust and well-defined crisis management process to ensure the safety and security of all our guests while they travel with us.",
    },
    {
      icon: IMAGES.ab11,
      heading: "On-Site Team",
      desc: "We are deeply committed to developing our people and investing in training for our guides, drivers, and local regional partners. We foster new ideas and embrace innovation to ensure that our team consistently meets guest expectations and delivers exceptional service every time.",
    },
  ];

  return (
    <Layout>
      <div className="about-container">
        <div className="bg-overlay">
          <h1 className="hero-title">About Us</h1>
        </div>
      </div>

      <div className={`${fixed && "topfixed"} about-tabs`}>
        <h2
          className={`${tab === "Our Story" && "active"}`}
          onClick={() => {
            setTab("Our Story");
            handleClick(OurStoryRef);
          }}
        >
          Our Story
        </h2>
        <h2
          className={`${tab === "Values and Mission" && "active"}`}
          onClick={() => {
            setTab("Values and Mission");
            handleClick(valuesMissionRef);
          }}
        >
          Values and Mission
        </h2>
        <h2
          className={`${tab === "Why Us" && "active"}`}
          onClick={() => {
            setTab("Why Us");
            handleClick(whyUsRef);
          }}
        >
          Why Us
        </h2>
        <h2
          className={`${tab === "Our Team" && "active"}`}
          onClick={() => {
            setTab("Our Team");
            handleClick(ourTeamRef);
          }}
        >
          Our Team
        </h2>
      </div>

      <div ref={OurStoryRef}>
        <OurStory />
      </div>

      <div ref={valuesMissionRef} className="values-and-mission">
        <div className="left order-2 order-lg-1">
          <h2>Values and Mission</h2>

          <p>
            At Exotic India Vacations, trust and reliability are the bedrock of
            our partnerships. We boast a robust network of trusted travel
            collaborators and deep regional expertise, enabling us to tailor
            bespoke travel experiences that meet each client's unique
            preferences. This sets us apart in the industry.
          </p>
          <p>
            Our commitment is to serve as a dependable ally for all our business
            associates and destination service resellers. By staying attuned to
            the evolving needs of modern travellers, we lead in curating
            unforgettable journeys. Our ethos is defined by passion and
            enthusiasm, which we channel into every interaction with our
            partners and their clients.
          </p>
          <p>
            We are your dedicated, passionate, and authentic ally, committed to
            simplifying travel experiences for all travellers
          </p>
        </div>
        <div className="right order-1 order-lg-2"></div>
      </div>

      <div ref={whyUsRef} className="why-us">
        <h1 className="cursive">Why Us?</h1>
        <h2 className="fs-5">
          We don't just focus on the breathtaking destinations we guide you to;
          we prioritize crafting remarkable journeys that make every moment of
          your trip unforgettable. It's about the extraordinary experiences we
          create along the way that set us apart
        </h2>
        <p>
          From the initial idea to the final review, we serve as your dedicated
          guide throughout. Our exceptional team ensures authentic and
          innovative destination experiences, all while securing preferred rates
          for you.
        </p>
        <Slider ref={arrowRef} {...settings}>
          {whyUsArray?.map((item, index) => {
            return (
              <div className="why-us-feature">
                <div className="feature">
                  <div className="icon">
                    <img src={item?.icon} alt="" />
                  </div>
                  <h4>{item?.heading}</h4>
                  <span>{item.desc}</span>
                </div>
              </div>
            );
          })}
        </Slider>
        {/* <div className="destinations-slider-btns">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon left-arrow" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon right-arrow" />
          </div>
        </div> */}
      </div>

      <div ref={ourTeamRef}>
        <OurTeam />
      </div>
    </Layout>
  );
};

export default About;
