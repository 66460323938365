import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import { message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/features/userSlice";
import PhoneInput from "react-phone-input-2";
import "./Contact.css";

const Contact = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    msg: "",
    country: "",
    countryCode: "",
  });
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);

  const fetchAddress = async () => {
    try {
      const res = await axios.get("/api/admin/get-address");
      if (res.data.success) {
        setData(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleMobileChange = (phone, country) => {
    const countryCode = country.dialCode;
    const mobileNumber = phone.slice(countryCode.length);
    setForm((prevForm) => ({
      ...prevForm,
      mobile: mobileNumber,
      countryCode: countryCode,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      form?.name === "" ||
      form?.email === "" ||
      form?.mobile === "" ||
      form?.msg.length === 0
    ) {
      setError(true);
      return;
    }
    if (form.mobile.length !== 10) {
      return message.error("Please enter a 10-digit mobile number");
    }
    try {
      const res = await axios.post("/api/contact/add-contact-form", form);
      if (res.data.success) {
        message.success(res.data.message);
        setForm({
          name: "",
          email: "",
          mobile: "",
          msg: "",
          country: "",
          countryCode: "",
        });
        setError(false);
      } else {
        message.error(res.data.message);
        setError(false);
      }
    } catch (error) {
      setError(false);
      console.log(error);
    }
  };

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        dispatch(setUser(res.data.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Layout>
      <div className="about-container contact-container">
        <div className="bg-overlay">
          <h1 className="hero-title">Contact</h1>
        </div>
      </div>

      <div className="office-address-container">
        <h1>Our Location</h1>
        <div className="row">
          <div className="address">
            <h4>Office Address</h4>
            <span
              dangerouslySetInnerHTML={{ __html: data?.address }}
              style={{ whiteSpace: "pre-wrap" }}
            />
          </div>
          <div className="address">
            <h4>Write Us</h4>
            <p>{data?.email}</p>
          </div>
          <div className="address">
            <h4>Call Us</h4>
            <p>{data?.mobile}</p>
            {data?.mobileTwo && <p>{data?.mobileTwo}</p>}
          </div>
        </div>
      </div>

      <div className="contact-form">
        <h2>Write Us..</h2>
        <div className="row mt-4">
          <div className="col-sm-12 col-12 col-md-6 col-lg-6">
            <div className="form-fields mb-3">
              <input
                name="name"
                type="text"
                className="form-control"
                placeholder="Name"
                value={form?.name}
                onChange={handleChange}
              />
              {error && form?.name === "" && (
                <span className="text-start text-danger d-block">
                  <small>Enter name</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-12 col-md-6 col-lg-6">
            <div className="form-fields mb-3">
              <input
                name="email"
                type="text"
                className="form-control"
                placeholder="Email"
                value={form?.email}
                onChange={handleChange}
              />
              {error && form?.email === "" && (
                <span className="text-start text-danger d-block">
                  <small>Enter email</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-12 col-md-6 col-lg-6">
            <div className="country-code form-fields mb-3">
              <PhoneInput
                country={"in"}
                value={countryCode + mobile}
                onChange={handleMobileChange}
              />
              {error && form?.mobile === "" && (
                <span className="text-start text-danger d-block">
                  <small>Enter mobile</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-12 col-md-6 col-lg-6">
            <div className="form-fields mb-3">
              <input
                name="country"
                type="text"
                className="form-control"
                placeholder="Country"
                value={form?.country}
                onChange={handleChange}
              />
              {error && form?.country === "" && (
                <span className="text-start text-danger d-block">
                  <small>Enter country</small>
                </span>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-fields mb-3">
              <textarea
                name="msg"
                className="form-control"
                rows={5}
                value={form?.msg}
                placeholder="Message"
                onChange={handleChange}
              ></textarea>
              {error && form?.msg === "" && (
                <span className="text-start text-danger d-block">
                  <small>Enter message</small>
                </span>
              )}
            </div>
            <button className="theme-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
