import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link, useNavigate } from "react-router-dom";
import "./SideMenu.css";
import axios from "axios";
import { message } from "antd";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const [form, setForm] = useState(null);

  const fetchAddress = async () => {
    try {
      const res = await axios.get("/api/admin/get-address");
      if (res.data.success) {
        setForm(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  function clearSpace(value) {
    return form && value.replace(/\s+/g, "");
  }

  return (
    <div className={`sidemenu-container ${sideMenu ? "active" : ""}`}>
      <div className="sidemenu">
        <HighlightOffIcon
          onClick={() => setSideMenu(!sideMenu)}
          className="close-icon"
        />
        <ul>
          <li
            onClick={() => {
              navigate("/about");
              setSideMenu(!sideMenu);
            }}
          >
            About Us
          </li>
          <li
            onClick={() => {
              navigate("/services");
              setSideMenu(!sideMenu);
            }}
          >
            Our Services
          </li>
          <li
            onClick={() => {
              navigate("/destinations");
              setSideMenu(!sideMenu);
            }}
          >
            Destinations
          </li>
          <li
            onClick={() => {
              navigate("/experiences");
              setSideMenu(!sideMenu);
            }}
          >
            Experiences
          </li>
          <li
            onClick={() => {
              navigate("/contact");
              setSideMenu(!sideMenu);
            }}
          >
            Contact Us
          </li>
        </ul>
        <div className="bottom-social">
          <Link
            target="_blank"
            to="https://www.instagram.com/exoticindiavacations"
          >
            <InstagramIcon className="icon" />
          </Link>
          <Link
            target="_blank"
            to="https://m.facebook.com/profile.php?id=61558980203361"
          >
            <FacebookIcon className="icon" />
          </Link>
          <Link
            target="_blank"
            to={`https://wa.me/${clearSpace(form?.mobile)}`}
          >
            <WhatsAppIcon className="icon" />
          </Link>
          <Link target="_blank" to="mailto:info@exoticindiavacations.com">
            <EmailIcon className="icon" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
