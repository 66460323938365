import React from "react";
import Layout from "../components/Layout/Layout";
import "./Destinations.css";
import HomeDestinations from "../components/Home/HomeDestinations";

const Destinations = () => {
  return (
    <Layout>
      <div className="about-container destinations">
        <div className="bg-overlay">
          <h1 className="hero-title">Destinations</h1>
        </div>
      </div>
      <div className="dest-about">
        <p>
          A land where every hue is twice as vivid, where every street is
          infused with the delightful aromas of local spices, and where temples,
          monasteries, and mosques coexist in serene harmony. The Indian
          subcontinent, comprising India, Nepal, Bhutan, and Sri Lanka, is a
          dazzling treasure trove of natural splendor and cultural richness.
          From the golden beaches of Kerala and Goa to the snow-capped peaks of
          the Himalayas in Nepal, the region offers a diverse range of
          landscapes. India's 38 UNESCO World Heritage Sites, including the Taj
          Mahal and the ancient ruins of Hampi, stand as testaments to its rich
          history. Nepal's Mount Everest, Bhutan's monasteries, and Sri Lanka's
          ancient cities and lush rainforests further enhance the region's
          allure.
        </p>
        <p>
          The cultural tapestry of the subcontinent is equally mesmerizing.
          India, with over 2,000 ethnic groups and more than 1,600 spoken
          languages, is a melting pot of traditions and festivals. Holi, Diwali,
          and the Kumbh Mela are just a few of the vibrant celebrations that
          occur year-round. Nepal's festivals, such as Dashain and Tihar,
          reflect its rich heritage, while Bhutan's Gross National Happiness
          philosophy highlights its focus on cultural preservation. Sri Lanka's
          diverse festivals, historic sites like the Sigiriya Rock Fortress, and
          the sacred city of Kandy showcase its cultural wealth. The region
          engages all the senses with the sound of temple bells, the sight of
          vibrant saris, the taste of spicy curries, and the touch of ancient
          stones, creating an unforgettable journey.
        </p>
      </div>
      <HomeDestinations />
    </Layout>
  );
};

export default Destinations;
