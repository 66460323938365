import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import { message, Modal } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import "./AdminAddEditLocations.css";

const AdminAddEditLocations = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [step, setStep] = useState(0);
  const [regions, setRegions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(null);
  const [loader, setLoader] = useState(null);

  function handleDataChange(e) {
    const { name, value } = e.target;
    if (data && name in data) {
      setData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    } else {
      console.warn(`Key "${name}" does not exist in data`);
    }
  }

  async function handleDeleteRegionImage(imageIndex) {
    try {
      const res = await axios.post("/api/admin/delete-region-image", {
        regionIndex: index,
        imageIndex: imageIndex,
        id: params.id,
      });
      if (res.data.success) {
        message.success(res.data.message);
        setIsModalVisible(false);
        getLocation();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // function handleRegionFileChange(e) {
  //   const files = Array.from(e.target.files);
  //   setData({ ...data, pictures: [...data.pictures, ...files] });
  // }
  function handleRegionFileChange(e) {
    const files = Array.from(e.target.files);
    setData((prevData) => ({
      ...prevData,
      pictures: prevData.pictures ? [...prevData.pictures, ...files] : files,
    }));
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("id", params.id);
      formData.append("index", index);

      data.pictures.forEach((file, index) => {
        formData.append(`pictures[${index}]`, file);
      });

      const res = await axios.post("/api/admin/edit-region", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setIsModalVisible(false);
        getLocation();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [form, setForm] = useState({
    name: "",
    overview: "",
    tourImage: "",
    bannerImage: "",
    gallery: [],
    regions: [],
    usefulInformation: [],
    itinerary: [],
  });

  const steps = [
    "Location",
    "Tour Img, Banner Img, Gallery",
    "Regions",
    "Useful Information",
    "Itinerary",
  ];

  // ======================= GET LOCATION
  async function getLocation() {
    try {
      const res = await axios.post(
        "/api/admin/get-location",
        {
          id: params.id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setRegions(res.data.data.regions);
        setForm(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (params.id) {
      getLocation();
    }
  }, [params]);
  // ======================= GET LOCATION
  // ======================= GET LOCATION

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  function handleFileChange(e) {
    const files = Array.from(e.target.files);
    setForm({ ...form, gallery: [...form.gallery, ...files] });
  }
  function handleTourFileChange(e) {
    setForm({ ...form, tourImage: e.target.files[0] });
  }
  function handleBannerFileChange(e) {
    setForm({ ...form, bannerImage: e.target.files[0] });
  }

  function handleAddRegion() {
    const regionTitle = document.getElementById("region-title").value;
    const regionDescription =
      document.getElementById("region-description").value;
    const regionFiles = Array.from(
      document.getElementById("region-files").files
    );
    setForm({
      ...form,
      regions: [
        ...form.regions,
        {
          title: regionTitle,
          description: regionDescription,
          pictures: regionFiles,
        },
      ],
    });

    // Clear the input fields
    document.getElementById("region-title").value = "";
    document.getElementById("region-description").value = "";
    document.getElementById("region-files").value = "";
  }

  function handleRemoveRegion(index) {
    const newRegions = form.regions.filter((_, i) => i !== index);
    setForm({ ...form, regions: newRegions });
  }

  async function handleDeleteRegion(index, title) {
    const newR = regions.filter((_, i) => i !== index);
    setRegions(newR);
    try {
      const res = await axios.post("/api/admin/delete-region", {
        title: title,
        id: params.id,
      });
      if (res.data.success) {
        message.success(res.data.message);
        getLocation();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleAddUsefulInfo() {
    const infoTitle = document.getElementById("info-title").value;
    const infoDescription = document.getElementById("info-description").value;
    setForm({
      ...form,
      usefulInformation: [
        ...form.usefulInformation,
        { title: infoTitle, description: infoDescription },
      ],
    });

    document.getElementById("info-title").value = "";
    document.getElementById("info-description").value = "";
  }

  function handleRemoveUsefulInfo(index) {
    const newUsefulInfo = form.usefulInformation.filter((_, i) => i !== index);
    setForm({ ...form, usefulInformation: newUsefulInfo });
  }

  function handleAddItinerary() {
    const itineraryTitle = document.getElementById("itinerary-title").value;
    const itineraryDescription = document.getElementById(
      "itinerary-description"
    ).value;
    setForm({
      ...form,
      itinerary: [
        ...form.itinerary,
        { title: itineraryTitle, description: itineraryDescription },
      ],
    });

    document.getElementById("itinerary-title").value = "";
    document.getElementById("itinerary-description").value = "";
  }

  function handleRemoveItinerary(index) {
    const newItinerary = form.itinerary.filter((_, i) => i !== index);
    setForm({ ...form, itinerary: newItinerary });
  }

  async function handleSubmit() {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("name", form.name);
      formData.append("overview", form.overview);
      formData.append("tourImage", form.tourImage);
      formData.append("bannerImage", form.bannerImage);

      if (params.id) {
        formData.append("id", params.id);
      }

      form.gallery.forEach((file, index) => {
        formData.append(`gallery[${index}]`, file);
      });

      form.regions.forEach((region, regionIndex) => {
        formData.append(`regions[${regionIndex}][title]`, region.title);
        formData.append(
          `regions[${regionIndex}][description]`,
          region.description
        );
        region.pictures.forEach((file, fileIndex) => {
          formData.append(
            `regions[${regionIndex}][pictures][${fileIndex}]`,
            file
          );
        });
      });
      form.usefulInformation.forEach((info, index) => {
        formData.append(`usefulInformation[${index}][title]`, info.title);
        formData.append(
          `usefulInformation[${index}][description]`,
          info.description
        );
      });
      form.itinerary.forEach((item, index) => {
        formData.append(`itinerary[${index}][title]`, item.title);
        formData.append(`itinerary[${index}][description]`, item.description);
      });

      const res = await axios.post("/api/admin/add-location", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data", // Ensure correct content type for FormData
        },
      });

      if (res.data.success) {
        setLoader(false);
        navigate("/admin-locations");
        message.success(res.data.message);
      } else {
        setLoader(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }

  async function handleDeleteImage(index) {
    try {
      const res = await axios.post(
        "/api/admin/delete-gallery-img",
        { id: params?.id, index: index },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getLocation();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">
            Step {step + 1} : {steps[step]}
          </h3>
        </div>
        <hr />

        {/* STEP 1 BASIC INFO  */}
        {step === 0 && (
          <div className="form-container">
            <div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter location name"
                  name="name"
                  onChange={handleChange}
                  value={form.name}
                />
              </div>
              <div className="form-fields mb-4">
                <label htmlFor="overview" className="form-label">
                  Overview
                </label>
                <textarea
                  rows={10}
                  name="overview"
                  className="form-control"
                  placeholder="Write a description for the location"
                  onChange={handleChange}
                  value={form.overview}
                ></textarea>
              </div>
            </div>
          </div>
        )}

        {/* STEP 2  GALLERY ARRAY */}
        {step === 1 && (
          <div className="form-container">
            <div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="gallery">
                  Gallery
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="gallery"
                  multiple
                  onChange={handleFileChange}
                />
                <span className="text-danger">
                  <small>Dimension: 3000x1800 | Size: Less than 1MB </small>
                </span>
                <div className="gallery-preview">
                  {form?.gallery?.map((item, index) => {
                    return (
                      <div className="gallery-img-box">
                        <DeleteIcon
                          onClick={() => handleDeleteImage(index)}
                          className="icon"
                        />
                        <img
                          className="me-2 mt-3 rounded"
                          width="100px"
                          src={item}
                          alt="img"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="gallery">
                  Tour Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="gallery"
                  onChange={handleTourFileChange}
                />
                <span className="text-danger">
                  <small>Dimension: 1000x700 | Size: Less than 1MB </small>
                </span>
              </div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="gallery">
                  Banner Image
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="gallery"
                  onChange={handleBannerFileChange}
                />
                <span className="text-danger">
                  <small>Dimension: 1920x912 | Size: Less than 1MB </small>
                </span>
              </div>
            </div>
          </div>
        )}

        {/* STEP 3  REGION ARRAY */}
        {step === 2 && (
          <div className="form-container">
            <div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="region-title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="region-title"
                  placeholder="Enter region"
                />
              </div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="region-files">
                  Pictures
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="region-files"
                  multiple
                />
                <span className="text-danger">
                  <small>Dimension: 733x396 | Size: Less than 1MB </small>
                </span>
              </div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="region-description">
                  Description
                </label>
                <textarea
                  id="region-description"
                  className="form-control"
                  rows={5}
                  placeholder="Enter description"
                ></textarea>
                <button className="theme-btn mt-4" onClick={handleAddRegion}>
                  Add
                </button>
              </div>
              <table className="table mb-5">
                <thead>
                  <tr>
                    <th>Region</th>
                    <th>Pictures</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {form?.regions?.map((region, index) => (
                    <tr key={index}>
                      <td>{region.title}</td>
                      <td>{region.pictures.length}</td>
                      <td>{region.description}</td>
                      <td>
                        <button
                          className="admin-btn bg-warning me-2"
                          onClick={() => {
                            showModal();
                            setData(region);
                            setIndex(index);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="admin-btn bg-danger"
                          onClick={() => {
                            handleRemoveRegion(index);
                            handleDeleteRegion(index, region.title);
                          }}
                        >
                          Remove
                        </button>
                      </td>
                      <Modal
                        title="Edit Region"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText="Submit"
                        cancelText="Cancel"
                      >
                        <div className="edit-region">
                          <input
                            value={data?.title}
                            className="form-control"
                            type="text"
                            onChange={handleDataChange}
                            name="title"
                          />
                          <input
                            onChange={handleRegionFileChange}
                            className="form-control"
                            name="pictures"
                            type="file"
                            multiple
                          />
                          <div className="edit-region-images">
                            {data?.pictures?.map((item, imageIndex) => {
                              return (
                                <img
                                  onClick={() =>
                                    handleDeleteRegionImage(imageIndex)
                                  }
                                  src={item}
                                  alt=""
                                />
                              );
                            })}
                          </div>
                          <textarea
                            onChange={handleDataChange}
                            value={data?.description}
                            className="form-control"
                            name="description"
                            id=""
                          ></textarea>
                        </div>
                      </Modal>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* STEP 4 USEFUL INFORMATION ARRAY */}
        {step === 3 && (
          <div className="form-container">
            <div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="info-title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="info-title"
                  placeholder="Enter heading"
                />
              </div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="info-description">
                  Description
                </label>
                <textarea
                  id="info-description"
                  className="form-control"
                  rows={5}
                  placeholder="Enter description"
                ></textarea>
                <button
                  className="theme-btn mt-4"
                  onClick={handleAddUsefulInfo}
                >
                  Add
                </button>
              </div>
              <table className="table mb-5">
                <thead>
                  <tr>
                    <th>Heading</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {form?.usefulInformation?.map((info, index) => (
                    <tr key={index}>
                      <td>{info.title}</td>
                      <td>{info.description}</td>
                      <td>
                        <button
                          className="admin-btn bg-danger"
                          onClick={() => handleRemoveUsefulInfo(index)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* STEP 5  ITINERARY ARRAY */}
        {step === 4 && (
          <div className="form-container">
            <div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="itinerary-title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="itinerary-title"
                  placeholder="Enter heading"
                />
              </div>
              <div className="form-fields mb-4">
                <label className="form-label" htmlFor="itinerary-description">
                  Description
                </label>
                <textarea
                  id="itinerary-description"
                  className="form-control"
                  rows={5}
                  placeholder="Enter description"
                ></textarea>
                <button className="theme-btn mt-4" onClick={handleAddItinerary}>
                  Add
                </button>
              </div>
              <table className="table mb-5">
                <thead>
                  <tr>
                    <th>Heading</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {form?.itinerary?.map((info, index) => (
                    <tr key={index}>
                      <td>{info.title}</td>
                      <td>{info.description}</td>
                      <td>
                        <button
                          className="admin-btn bg-danger"
                          onClick={() => handleRemoveItinerary(index)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="steps-buttons">
          {step === 0 && <span></span>}
          {step !== 0 && (
            <button className="theme-btn" onClick={() => setStep(step - 1)}>
              Prev
            </button>
          )}
          {step !== 4 && (
            <button
              className="theme-btn"
              onClick={() => {
                setStep(step + 1);
                console.log(form);
              }}
            >
              Next
            </button>
          )}
          {step === 4 && params.id ? (
            <button
              disabled={loader}
              className="theme-btn"
              onClick={handleSubmit}
            >
              Update
              {loader && (
                <div
                  className="spinner-grow spinner-grow-sm ms-1"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          ) : (
            step === 4 && (
              <button className="theme-btn" onClick={handleSubmit}>
                Submit
              </button>
            )
          )}
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAddEditLocations;
