import React from "react";
import Faq from "../components/Home/Faq";
import Layout from "../components/Layout/Layout";

const FrequentlyAskedQuestions = () => {
  return (
    <Layout>
      <Faq />
    </Layout>
  );
};

export default FrequentlyAskedQuestions;
