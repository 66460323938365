import React from "react";
import Layout from "../components/Layout/Layout";
import "./Terms.css";

const ShippingPolicy = () => {
  return (
    <Layout>
      <div className="bg-heading">
        <h4>
          <b>Terms & Conditions</b>
        </h4>
      </div>
      <div className="terms-container">
        <p>
          All correspondence(s) in respect of Tours / Travel Service bookings
          should be addressed to M/s. Exotic India Vacations
        </p>
        <h5 className="mt-5">Payments:</h5>
        <p>
          - For all contracted services, an advance payment is required to
          secure the booking on a confirmed basis. The remaining balance can be
          paid before your departure from your country. Management reserves the
          right to determine the advance payment amount based on the nature of
          the service and the time remaining until the service commences.
        </p>
        <p>
          - Additionally, for certain cases such as luxury train journeys or
          hotel and resort bookings during peak seasons (Christmas, New Year,
          Special Events), full payment must be made in advance.
        </p>
        <h5 className="mt-5">Mode of Payment:</h5>
        <p>
          - Overseas advance payment can be made through Wire Transfer to our
          bank.
        </p>
        <h5 className="mt-5">Cancellation Policy:</h5>
        <p>
          - In the event of cancellation of tour / travel services due to any
          avoidable / unavoidable reason/s we must be notified of the same in
          writing. Cancellation charges will be effective from the date we
          receive advice in writing, and cancellation charges would be as
          follows:
        </p>
        <p>60 days prior to arrival: No cancellation fees. </p>
        <p>59-45 days prior to arrival: 25% of the Tour Price</p>
        <p>44-30 days prior to arrival: 50% of the Tour Price</p>
        <p>29-15 days prior to arrival: 70% of the Tour Price</p>
        <p>14-00 Days prior to arrival date: 100% of the Tour Price</p>
        <p>
          Note: Written cancellation will accept on all working days, except
          Sunday, Any cancellation sent on Sunday's will be considered on next
          working day (Monday).
        </p>
        <p>
          For the X-mas and new year period from 20 Dec to 05 Jan the payment is
          non-refundable.
        </p>
        <p>
          In case you cancel the trip after commencement, refund would be
          restricted to a limited amount only which too would depend on the
          amount that we would be able to recover from the hoteliers/
          contractors we patronize. For unused hotel accommodation, chartered
          transportation & missed meals etc. we do not bear any responsibility
          to refund.
        </p>
        <h5 className="mt-5">Our Liabilities & Limitations:</h5>
        <p>
          - Please note that after the finalization of the Tour Price, if there
          are any Hike in entrance fees of monuments / museums, Taxes, fuel cost
          or guide charges – by Govt of India, the same would be charged as
          extra.
        </p>
        <p>
          - Exotic India Vacations act only in the capacity of agent for the
          hotels, airlines, transporters, railways & contractors providing other
          services & all exchange orders, receipts, contracts & tickets issued
          by us are issued subject to terms & conditions under which these
          services are provided by them.
        </p>
        <p>
          - All itineraries are sample itineraries, intended to give you a
          general idea of the likely trip schedule. Numerous factors such as
          weather, road conditions, the physical ability of the participants
          etc. may dictate itinerary changes either before the tour or while on
          the trail. We reserve the right to change any schedule in the interest
          of the trip participants' safety, comfort & general well-being.
        </p>
        <p>
          - Our rates are based on the prevailing rates as negotiated by us with
          the hotels, airlines etc. Hotels and Airlines retain the right to
          modify the rates without notice. In case of such changes the rates
          quoted before the modification, can be changed by us according to the
          modifications by hotels or airlines. All hotel bookings are based on
          usual check in and check out time of the hotels until unless indicated
          in the itinerary.
        </p>
        <p>
          - We shall not be responsible for any delays & alterations in the
          programme or expenses incurred – directly or indirectly – due to
          natural hazards, flight cancellations, accident, breakdown of
          machinery or equipment, breakdown of transport, weather, sickness,
          landslides, political closures or any untoward incidents
        </p>
        <p>
          - We shall not be responsible for any loss, injury or damage to
          person, property, or otherwise in connection with any accommodation,
          transportation or other services, resulting – directly or indirectly –
          from any act of GOD, dangers, fire, accident, breakdown in machinery
          or equipment, breakdown of transport, wars, civil disturbances,
          strikes, riots, thefts, pilferages, epidemics, medical or custom
          department regulations, defaults, or any other causes beyond our
          control.
        </p>
        <p>
          - We do not have any insurance policy covering the expenses for
          accident, sickness, loss due to theft, or any other reasons. Visitors
          are advised to seek such insurance arrangements in their home country.
          All baggage’s & personal property/s at all times are at the client's
          risk.
        </p>
        <p>
          - Please Note: We will not be responsible for any costs arising out of
          unforeseen circumstances like landslides, road blocks, bad weather,
          etc.
        </p>
      </div>
    </Layout>
  );
};

export default ShippingPolicy;
