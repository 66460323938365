import React from "react";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import HowToPlan from "../components/Home/HowToPlan.js";
import OurStory from "../components/Home/OurStory.js";
import HomeDestinations from "../components/Home/HomeDestinations.js";
import HomeServices from "../components/Home/HomeServices.js";
import HomeVideos from "../components/Home/HomeVideos.js";
import Testimonials from "../components/Testimonials.js";
import "./Home.css";

const Home = () => {
  return (
    <Layout>
      <HeroSection />
      {/* <HowToPlan /> */}
      <OurStory />
      <HomeDestinations />
      <HomeServices />
      <HomeVideos />
      <Testimonials />
      {/* <SliderText
        text={"TRENDING GAMES TOP UP"}
        direction={"left"}
        bg={"#ff4900"}
        fs={16}
      /> */}
    </Layout>
  );
};

export default Home;
