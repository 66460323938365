import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import axios from "axios";
import { message, Table, Input, Button } from "antd";
import "./AdminQueries.css";

const AdminAddress = () => {
  const [form, setForm] = useState({
    address: "",
    mobile: "",
    mobileTwo: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  function handleChange(e) {
    setForm({ ...form, [e.target.name]: e.target.value });
  }

  const fetchAddress = async () => {
    try {
      const res = await axios.get("/api/admin/get-address");
      if (res.data.success) {
        setForm(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  async function handleUpdate() {
    try {
      setLoading(true);
      const res = await axios.post("/api/admin/update-address", form, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);

        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Address / Email / Contact</h3>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <div className="form-fields mb-3">
            <input
              type="text"
              placeholder="Enter Email"
              className="form-control"
              onChange={handleChange}
              value={form?.email}
              name="email"
            />
          </div>
          <div className="form-fields mb-3">
            <input
              type="text"
              placeholder="Enter Mobile"
              className="form-control"
              onChange={handleChange}
              value={form?.mobile}
              name="mobile"
            />
          </div>
          <div className="form-fields mb-3">
            <input
              type="text"
              placeholder="Enter Mobile Two"
              className="form-control"
              onChange={handleChange}
              value={form?.mobileTwo}
              name="mobileTwo"
            />
          </div>
          <div className="form-fields mb-3">
            <textarea
              placeholder="Enter Address"
              className="form-control"
              onChange={handleChange}
              value={form?.address}
              name="address"
              rows={5}
            ></textarea>
          </div>
          <button onClick={handleUpdate} className="theme-btn">
            Update
            {loading && (
              <div className="spinner-grow spinner-grow-sm ms-1" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminAddress;
