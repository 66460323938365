import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { message } from "antd";
import India from "../img/home/india.webp";
import AddIcon from "@mui/icons-material/Add";
import Regions from "../components/Destinations/Regions.js";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Slider from "react-slick";
import { Collapse } from "antd";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./DestinationPage.css";

const DestinationPage = () => {
  const { Panel } = Collapse;
  const params = useParams();

  const [tab, setTab] = useState("Overview");
  const [fixed, setFixed] = useState(false);
  const [info, setInfo] = useState(false);
  const [dest, setDest] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const arrowRef = useRef();
  const overviewRef = useRef(null);
  const galleryRef = useRef(null);
  const regionsRef = useRef(null);
  const usefulInfoRef = useRef(null);
  const itineraryRef = useRef(null);
  const itineraryKeyRef = useRef(null);

  const handleClick = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  async function getDestination() {
    try {
      const res = await axios.post("/api/admin/get-location-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setDest(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDestination();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: false,
    prevArrow: false,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };

  const bannerImageUrl = `${dest?.bannerImage}`;

  return (
    <Layout>
      <div className="dest-container">
        <img src={bannerImageUrl} alt="" />
        <div className="bg-overlay">
          <h1 className="hero-title">{params.name}</h1>
        </div>
      </div>

      <div className={`${fixed && "topfixed"} about-tabs`}>
        <h2
          className={`${tab === "Overview" && "active"}`}
          onClick={() => {
            setTab("Overview");
            handleClick(overviewRef);
          }}
        >
          Overview
        </h2>
        {dest?.gallery?.length > 0 && (
          <h2
            className={`${tab === "Gallery" && "active"}`}
            onClick={() => {
              setTab("Gallery");
              handleClick(galleryRef);
            }}
          >
            Gallery
          </h2>
        )}
        {dest?.regions?.length > 0 && (
          <h2
            className={`${tab === "Regions" && "active"}`}
            onClick={() => {
              setTab("Regions");
              handleClick(regionsRef);
            }}
          >
            Regions
          </h2>
        )}
        {dest?.usefulInformation?.length > 0 && (
          <h2
            className={`${tab === "Useful Information" && "active"}`}
            onClick={() => {
              setTab("Useful Information");
              handleClick(usefulInfoRef);
            }}
          >
            Useful Information
          </h2>
        )}
        {dest?.itinerary?.length > 0 && (
          <h2
            className={`${tab === "Itinerary" && "active"}`}
            onClick={() => {
              setTab("Itinerary");
              handleClick(itineraryRef);
            }}
          >
            Itinerary
          </h2>
        )}
      </div>

      {/* OVERVIEW  */}
      <div ref={overviewRef} className="overview-container text-center">
        <h1 className="hrline">Overview</h1>
        <p>{dest?.overview}</p>
      </div>

      {/* GALLERY  */}
      {dest?.gallery?.length !== 0 && (
        <div ref={galleryRef} className="gallery-container">
          <Slider ref={arrowRef} {...settings}>
            {dest?.gallery?.map((item, index) => {
              return (
                <img src={`https://exoticindiavacations.com/${item}`} alt="" />
              );
            })}
          </Slider>
          <div className="gallery-slider-btn">
            <div onClick={() => arrowRef.current.slickPrev()}>
              <KeyboardArrowLeftIcon className="icon gla" />
            </div>
            <div onClick={() => arrowRef.current.slickNext()}>
              <KeyboardArrowRightIcon className="icon gra" />
            </div>
          </div>
        </div>
      )}

      {/* REGIONS */}
      {dest?.regions?.length !== 0 && (
        <div ref={regionsRef} className="regions-container">
          <Regions dest={dest?.regions} />
        </div>
      )}

      {/* USEFUL INFORMATION */}
      {dest?.itinerary?.length > 0 && (
        <div className="single-short-details itinerary">
          <h1 className="text-center">Itinerary</h1>
          <div
            className="Mon_font accordion accordion-flush mb-4"
            id="accordionFlushExample"
          >
            {dest?.itinerary?.map((item, index) => {
              return (
                <div key={index} className="accordion-item itinerary-item">
                  <div className="d-flex justify-content-center accordion-item-div">
                    <div className="accordion-item-day">Day {index + 1}</div>
                    <button
                      className="bg-light accordion-button collapsed px-0 accordian-item-button pe-2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse-${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse-${index}`}
                    >
                      <span className="ms-3">{item?.title}</span>
                    </button>
                  </div>
                  <div
                    id={`collapse-${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading-${index}`}
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body border m-2">
                      {item?.description?.split("\n").map((line, i) => (
                        <div key={i} className="my-2">
                          <span className="accordian-item-desc">{line}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* USEFUL INFORMATION */}
      <div ref={usefulInfoRef} className="useful-info-container">
        <div className={`useful-info ${info && "active"}`}>
          <CloseIcon className="icon" onClick={() => setInfo(!info)} />
          <h1>Useful Information</h1>
          <div className="useful">
            {dest?.usefulInformation?.map((item, index) => {
              return (
                <div key={index} className={`description`}>
                  <p>
                    <span>
                      <b>{item?.title}</b>
                    </span>
                    {" - "}
                    {item?.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <button onClick={() => setInfo(!info)} className="theme-btn">
          Useful Information <AddIcon className="ms-1" />
        </button>
      </div>
    </Layout>
  );
};

export default DestinationPage;
