import React, { useEffect, useRef } from "react";
import hero from "../../video/hero.mp4";
import ReactPlayer from "react-player";
import "./HeroSection.css";

const HeroSection = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, []);

  return (
    <div className="hero-container">
      <div className="overlay">
        <div className="hero-title">
          <div className="cursive">
            <h3 className="m-0 ">Discover Uniqueness in Every Journey</h3>
          </div>
          <span>
            Crafting sustainable and unforgettable travel experiences for the
            most discerning travelers.
          </span>
        </div>
        {/* <video ref={videoRef} loop autoPlay muted src={hero}></video> */}
        <ReactPlayer
          url={hero}
          playing
          loop
          muted
          playbackRate={0.6}
          width="100%"
          height="auto"
        />
      </div>
    </div>
  );
};

export default HeroSection;
