import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import "./Header.css";
import IMAGES from "../../img/image";

const Header = () => {
  const navigate = useNavigate();
  const [sideMenu, setSideMenu] = useState(false);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header-main ${sticky && "active"}`}>
      <div className="logo" onClick={() => navigate("/")}>
        <img src={IMAGES.exlogo} width="200px" alt="" />
      </div>
      <ul>
        <li
          className="d-none d-lg-block"
          onClick={() => navigate("/destinations")}
        >
          Destinations
        </li>
        <li className="d-none d-lg-block" onClick={() => navigate("/services")}>
          Our Services
        </li>
        <li className="d-none d-lg-block" onClick={() => navigate("/contact")}>
          Contact
        </li>
        <li onClick={() => setSideMenu(!sideMenu)}>
          <DragHandleIcon className="icon" />
        </li>
      </ul>
      <SideMenu setSideMenu={setSideMenu} sideMenu={sideMenu} />
      <Backdrop setSideMenu={setSideMenu} sideMenu={sideMenu} />
    </header>
  );
};

export default Header;
