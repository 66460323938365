import React from "react";
import "./OurStory.css";
import { useLocation, useNavigate } from "react-router-dom";

const OurStory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="our-story">
      <div className="content">
        <h1>Our Story</h1>
        <p>
          Welcome to Exotic India Vacations, a distinguished Destination
          Management Company (DMC) dedicated to crafting unforgettable travel
          experiences across India, Nepal, Bhutan, and Sri Lanka. Since our
          inception, we've meticulously curated every journey, ensuring
          excellence at every turn. Our handpicked team of experts is committed
          to exceeding your expectations, backed by strong partnerships and
          robust financial stability. We prioritize sustainability and community
          empowerment, offering enriching travel adventures that resonate
          deeply. Trust us to bring your travel dreams to life with seamless,
          meaningful journeys that leave a lasting impression
        </p>
        {location.pathname !== "/about" && (
          <button
            className="theme-btn-2 d-block m-auto"
            onClick={() => navigate("/about")}
          >
            Read More
          </button>
        )}
      </div>
    </div>
  );
};

export default OurStory;
