import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminDashboard.css";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dash, setDash] = useState(null);

  async function getDashboard() {
    try {
      const res = await axios.get("/api/admin/get-dashboard");
      if (res.data.success) {
        setDash(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Dashboard</h3>
      </div>
      <hr />
      <div className="admin-dashboard-container p-0">
        <div className="dash-card" onClick={() => navigate("/admin-locations")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{dash?.locationCount || 0}</b>
              )}
            </h1>
            <span>Locations</span>
          </div>
          <PointOfSaleIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-enquiries")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{dash?.queriesCount || 0}</b>
              )}
            </h1>
            <span>Enquiries</span>
          </div>
          <StayCurrentPortraitIcon className="icon" />
        </div>
        <div
          className="dash-card"
          onClick={() => navigate("/admin-testimonials")}
        >
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{dash?.testimonialCount || 0}</b>
              )}
            </h1>
            <span>Testimonials</span>
          </div>
          <StayCurrentPortraitIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-videos")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{4}</b>
              )}
            </h1>
            <span>Home Videos</span>
          </div>
          <StayCurrentPortraitIcon className="icon" />
        </div>
      </div>
      <hr />
      <h4>Recent Enquiries</h4>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Sr No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Country</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {dash?.queries?.map((item, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item?.name}</td>
                <td>{item?.email}</td>
                <td>{item?.mobile}</td>
                <td>{item?.country}</td>
                <td>{item?.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </AdminLayout>
  );
};

export default AdminDashboard;
