import IMAGES from "../../img/image";

const textArray = [
  {
    heading: "Tailor Made",
    desc: "With extensive knowledge and hands-on experience, our specialists are adept at creating unique and unforgettable itineraries for your guests. Each plan is carefully tailored to match the specific preferences, styles, budgets, and needs of your clients. Our committed team of professionals is always on hand to address your queries and provide the assistance you require. From imaginative, customized itineraries to expert on-the-ground operations, we collaborate with you to craft and deliver the finest travel experiences in the Indian Subcontinent.",
    img: IMAGES.tailor,
  },
  {
    heading: "Escorted Tours",
    desc: "A thoughtfully crafted itinerary, combined with an expert tour leader, is essential for turning even the most basic experiences into something remarkable. Our team of experienced tour leaders, supported by a strong local network, rigorous quality control, and solid supplier relationships, consistently surpasses expectations at every destination. We dedicate time to understanding our tour leaders’ perspectives and offer the training needed to elevate the guest experience. Our seamless service delivery enhances all group travel programs in the Indian subcontinent, backed by our in-house tour managers, who are renowned for their extensive knowledge and engaging approach.",
    img: IMAGES.exorted,
  },
  {
    heading: "Luxury Collection",
    desc: "We don't just envision exclusivity—we make it a reality. Our expert team is dedicated to perfecting every aspect of your guests' luxury vacation. From securing the finest hotels and exclusive venues to curating unique experiences and providing attentive concierge services, we ensure every moment of their journey surpasses expectations. Committed to delivering a 'True Maharaja or Maharani' experience, we understand the refined desires of luxury travelers and create the most exquisite travel plans for their getaways.",
    img: IMAGES.luxury,
  },
  {
    heading: "Luxury Trains",
    desc: "Embark on an opulent journey across India with our luxury trains. In partnership with esteemed brands committed to authentic service and sustainable practices, we showcase India's rich heritage while traversing its diverse landscapes. From the regal Maharajas' Express to the iconic Palace on Wheels, our trains offer an unparalleled travel experience through India's historic cities and scenic vistas. Experience the grandeur of palaces, vibrant local cultures, and breathtaking natural beauty with meticulously curated itineraries. Our travel experts possess extensive knowledge to assist our partners in crafting unforgettable rail journeys for their guests. Additionally, we provide comprehensive expertise as your service provider for pre/post tours and bespoke excursions throughout the Indian subcontinent.",
    img: IMAGES.trains,
  },
  {
    heading: "Special Interest",
    desc: "We take pride in offering a diverse portfolio of Exotic India Vacation's Special Interest Itineraries, each designed to provide unique, theme-based travel experiences throughout the Indian Subcontinent. Whether you seek inspiration for groups or individuals, we are here to craft and curate extraordinary journeys. Our meticulously designed itineraries, enriched with local immersions, are created by specialists with decades of experience and a deep passion for their craft, ensuring that every journey meets the expectations of today’s discerning traveler.",
    img: IMAGES.special,
  },
  {
    heading: "Healing & Wellness",
    desc: "From intensive wellness retreats to a blend of ancient and modern healing practices, we offer enriching experiences designed to transform the mind, body, and soul. Whether it’s a rejuvenating spa session, thoughtfully crafted healthy meals, meditation, holistic healing, Yoga, Ayurveda, Naturopathy, or other indigenous health practices, we meticulously plan every detail. Our strong, long-standing relationships with top suppliers ensure that guests receive the utmost benefits from their retreat holiday.",
    img: IMAGES.healing,
  },
  {
    heading: "Cruises",
    desc: "Explore the uncharted beauty of India with our exclusive River Expeditions. Partnering with trusted brands dedicated to authentic service and eco-conscious practices, we offer immersive experiences along two of India’s historic rivers—the Ganges and the Brahmaputra. Discover the rich regional heritage, vibrant cultures, and diverse wildlife up close with a range of carefully crafted itineraries that unveil the otherwise inaccessible wonders of these majestic rivers. Our travel experts, with their extensive knowledge and experience, are here to help you curate exceptional river cruise products for your guests. Additionally, we provide comprehensive expertise for offshore excursions and pre/post tours for ocean cruises throughout the Indian Subcontinent.",
    img: IMAGES.cruise,
  },
];

export default textArray;
