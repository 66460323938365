import React, { useState } from "react";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import { useNavigate } from "react-router-dom";
import "./HomeServices.css";
import textArray from "./serviceData";

const HomeServices = () => {
  const navigate = useNavigate();
  const [text, setText] = useState(textArray[0]);

  return (
    <div className="home-services-container text-center">
      <h1 className="text-center mb-3 mb-lg-5">Our Services</h1>
      <div className="row">
        <div className="text-start col-12 col-sm-12 col-md-4 col-lg-4">
          {textArray?.map((item, index) => {
            return (
              <h2
                key={index}
                className={`${item.heading !== text.heading && "active"}`}
                onClick={() => setText(item)}
              >
                {item.heading}
              </h2>
            );
          })}
        </div>
        {/* <ConnectingAirportsIcon className="icon" /> */}
        <div className="col-12 col-sm-12 col-md-8 col-lg-8">
          <div className="ser-img">
            <img src={text.img} alt={text.heading} />
            <div className="ser-text">
              <h2 className="text-white">{text.heading}</h2>
              <p>{text.desc}</p>
              <button
                onClick={() => navigate("/services")}
                className="theme-btn"
              >
                View More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
