import React, { useEffect, useRef, useState } from "react";
import tailor from "../../img/home/tailor.jpg";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./Regions.css";

const Regions = ({ dest }) => {
  const arrowRef = useRef();
  const [text, setText] = useState(dest && dest[0]);

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    nextArrow: false,
    prevArrow: false,
  };

  useEffect(() => {
    setText(dest && dest[0]);
  }, [dest]);

  return (
    <div className="home-services-container region-container text-center">
      <h1 className="text-center mb-3 mb-lg-5 hrline">Regions</h1>
      <div className="row">
        <div className="text-start col-12 col-sm-12 col-md-4 col-lg-4">
          {dest?.map((item, index) => {
            return (
              <h2
                key={index}
                className={`${item?.title !== text?.title && "text-muted"}`}
                onClick={() => setText(item)}
              >
                {item?.title}
              </h2>
            );
          })}
        </div>
        <div className="ser-cnt col-12 col-sm-12 col-md-8 col-lg-8">
          <div className="ser-img">
            <Slider ref={arrowRef} {...settings}>
              {text?.pictures?.map((item, index) => {
                return (
                  <img
                    src={`https://exoticindiavacations.com/${item}`}
                    alt=""
                  />
                );
              })}
            </Slider>
            <div className="ser-text">
              <h2 className="text-white">{text?.title}</h2>
            </div>
            <div className="ser-img-slider">
              <div onClick={() => arrowRef.current.slickPrev()}>
                <KeyboardArrowLeftIcon className="la" />
              </div>
              <div onClick={() => arrowRef.current.slickNext()}>
                <KeyboardArrowRightIcon className="ra" />
              </div>
            </div>
          </div>
          <p className="text-dark">{text?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Regions;
