import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/Layout/Layout";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Slider from "react-slick";
import "./Experiences.css";
import IMAGES from "../img/image";
import { useNavigate } from "react-router-dom";

const Experiences = () => {
  const arrowRef = useRef();
  const navigate = useNavigate();

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const exp = [
    { img: IMAGES.accessible, name: "Accessible Tours" },
    { img: IMAGES.arttour, name: "Art and Architecture" },
    { img: IMAGES.biketours, name: "Bike Tours" },
    { img: IMAGES.craft, name: "Craft and Textile Tours" },
    { img: IMAGES.culinary, name: "Culinary Tours" },
    { img: IMAGES.cultural, name: "Cultural Tours" },
    { img: IMAGES.family, name: "Family Tours" },
    { img: IMAGES.foodtour, name: "Food Tours" },
    { img: IMAGES.historytour, name: "History Tours" },
    { img: IMAGES.phtography, name: "Photography Tours" },
    { img: IMAGES.socialtour, name: "Social Ipmact Tours" },
    { img: IMAGES.villagetour, name: "Village Tours" },
    { img: IMAGES.walkingtour, name: "Walking Tours" },
  ];
  return (
    <Layout>
      <div className="about-container exp-container">
        <div className="bg-overlay">
          <h1 className="hero-title">Experiences</h1>
        </div>
      </div>
      <div className="experience-slider">
        <h1 className="text-center hrline mt-0">Our Experiences</h1>
        <p className="text-center">
          Discover our exclusive selection of curated experiences and let each
          story make you fall in love with the city all over again.
        </p>
        <Slider {...settings} ref={arrowRef}>
          {exp?.map((item, index) => {
            return (
              <div
                className="experiences-box"
                onClick={() => navigate("/contact")}
              >
                <div className="experiences">
                  <img src={item.img} alt="" />
                  <p>{item?.name}</p>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="exp-slider-btn">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon exp-slider-left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon exp-slider-right" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Experiences;
