const IMAGES = {
  // HOME
  logo: require("./logo.png"),
  exlogo: require("./exlogo.png"),
  wlogo: require("./wlogo.jpeg"),

  // HOME
  tajmahal: require("./home/tajmahal.jpg"),
  map: require("./home/map.jpg"),

  // services
  tailor: require("./home/suit.jpg"),
  exorted: require("./home/exorted.jpg"),
  luxury: require("./home/luxury.png"),
  trains: require("./home/trains.jpg"),
  special: require("./home/special.jpg"),
  healing: require("./home/healing.jpg"),
  cruise: require("./home/cruise.jpeg"),

  // About
  map: require("./about/about.jpg"),
  values: require("./about/values.webp"),
  ab1: require("./about/ab1.png"),
  ab2: require("./about/ab2.png"),
  ab3: require("./about/ab3.png"),
  ab4: require("./about/ab4.png"),
  ab5: require("./about/ab5.png"),
  ab6: require("./about/ab6.png"),
  ab7: require("./about/ab7.png"),
  ab8: require("./about/ab8.png"),
  ab9: require("./about/ab9.png"),
  ab10: require("./about/ab10.png"),
  ab11: require("./about/ab11.png"),
  // Exp
  biketours: require("./exp/biketours.jpg"),
  craft: require("./exp/crafttours.jpg"),
  culinary: require("./exp/culinary.jpg"),
  family: require("./exp/family.jpg"),
  foodtour: require("./exp/foodtour.jpg"),
  historytour: require("./exp/historytour.jpg"),
  phtography: require("./exp/phtography.webp"),
  socialtour: require("./exp/socialtour.jpg"),
  villagetour: require("./exp/villagetour.jpg"),
  walkingtour: require("./exp/walkingtour.jpg"),
  accessible: require("./exp/accessible.webp"),
  arttour: require("./exp/arttour.png"),
  cultural: require("./exp/cultural.jpg"),
};

export default IMAGES;
