import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import India from "../../img/home/india.webp";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import "./HomeDestinations.css";

const HomeDestinations = () => {
  const arrowRef = useRef();
  const navigate = useNavigate();
  const [dest, setDest] = useState(null);

  async function getAllDestinations() {
    try {
      const res = await axios.get("/api/admin/get-locations");
      if (res.data.success) {
        setDest(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllDestinations();
  }, []);

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="destinations-container text-center">
      <h1>Let's Explore Your Dream Destination Here!</h1>
      {/* <p>
        We have recommended popular destinatons every week so you don't have to
        worry about <br /> your destinations with ExoticVacationsIndia
      </p> */}
      <Slider ref={arrowRef} {...settings}>
        {dest?.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => navigate(`/destination/${item?.name}`)}
              className="destination"
            >
              <div className="dest">
                <img src={item.tourImage} alt="" />
                <h4>{item?.name}</h4>
              </div>
            </div>
          );
        })}
      </Slider>
      <div className="destinations-slider-btns">
        <div onClick={() => arrowRef.current.slickPrev()}>
          <KeyboardArrowLeftIcon className="icon test-slider-left" />
        </div>
        <div onClick={() => arrowRef.current.slickNext()}>
          <KeyboardArrowRightIcon className="icon test-slider-right" />
        </div>
      </div>
    </div>
  );
};

export default HomeDestinations;
