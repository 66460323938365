import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "../Footer/Footer.css";
import { message } from "antd";
import axios from "axios";
import IMAGES from "../../img/image";

const Footer = () => {
  const navigate = useNavigate();
  const [icon, setIcon] = useState(false);
  const [form, setForm] = useState(null);

  const fetchAddress = async () => {
    try {
      const res = await axios.get("/api/admin/get-address");
      if (res.data.success) {
        setForm(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIcon(true);
      } else {
        setIcon(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function clearSpace(value) {
    return form && value.replace(/\s+/g, "");
  }

  return (
    <React.Fragment>
      <div className="footer-container">
        <div className={`whatspp-icon ${icon && "active"}`}>
          <Link
            target="_blank"
            to={`https://wa.me/${clearSpace(form?.mobile)}`}
          >
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <img className="imglogo" src={IMAGES.exlogo} alt="" />
            <p className="mt-4 mb-0">
              <b>Address</b>
            </p>
            <span
              dangerouslySetInnerHTML={{ __html: form?.address }}
              style={{ whiteSpace: "pre-wrap" }}
            />
            <br />
            <span className="my-2">{form?.mobile}</span>
            {form?.mobileTwo && (
              <>
                <br />
                <span className="my-2">{form?.mobileTwo}</span>
              </>
            )}
            <br />
            <span className="my-2">{form?.email}</span>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>
              <b>Quick Links</b>
            </h6>
            <ul>
              <li onClick={() => navigate("/about")}>About Us</li>
              <li onClick={() => navigate("/destinations")}>Destinations</li>
              <li onClick={() => navigate("/services")}>Services</li>
              <li onClick={() => navigate("/contact")}>Contact</li>
              <li onClick={() => navigate("/experiences")}>Experiences</li>
            </ul>
          </div>
          <div className="quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>
              <b>Important Pages</b>
            </h6>
            <ul>
              <li onClick={() => navigate("/terms")}>Terms & Conditions</li>
              <li>
                <Link
                  to="https://indianvisaonline.gov.in/visa/index.html"
                  target="_blank"
                >
                  Indian Visa
                </Link>
              </li>
              <li>
                <Link to="https://bhutan.travel/visa" target="_blank">
                  Bhutan Visa
                </Link>
              </li>
              <li>
                <Link to="https://www.srilankaevisa.lk/" target="_blank">
                  Srilanka Visa
                </Link>
              </li>
              <li>
                <Link to="https://immigration.gov.np" target="_blank">
                  Nepal Visa
                </Link>
              </li>
            </ul>
          </div>
          <div className="quick-links col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>
              <b>Connect here</b>
            </h6>
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/exoticindiavacations"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://m.facebook.com/profile.php?id=61558980203361"
              >
                <FacebookIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to={`https://wa.me/${clearSpace(form?.mobile)}`}
              >
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:info@exoticindiavacations.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>

          <hr />
          <p className="text-center m-0">
            <small>
              All Rights Reserved © 2024 | EXOTIC INDIA VACATIONS |{" "}
              <br className="d-block d-md-none d-lg-none" />
              Website designed & developed by{" "}
              <Link
                target="_blank"
                style={{ color: "#ffca00", fontWeight: "500" }}
                to="https://aashirdigital.com/"
              >
                ~@aashirdigital
              </Link>
            </small>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
