import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/Layout/Layout";
import "./Service.css";

const Service = () => {
  const [tab, setTab] = useState("Tailor Made");
  const [fixed, setFixed] = useState(false);

  // Define refs for each section
  const tailorMadeRef = useRef(null);
  const escortedToursRef = useRef(null);
  const luxuryCollectionRef = useRef(null);
  const specialInterestRef = useRef(null);
  const healingWellnessRef = useRef(null);
  const luxuryTrainRef = useRef(null);
  const cruisesRef = useRef(null);

  // Handle scrolling to sections
  const handleClick = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Layout>
      <div className="about-container service-container">
        <div className="bg-overlay">
          <h1 className="hero-title">Our Services</h1>
        </div>
      </div>

      <div className={`${fixed && "topfixed"} about-tabs`}>
        <h2
          className={`${tab === "Tailor Made" && "active"}`}
          onClick={() => {
            setTab("Tailor Made");
            handleClick(tailorMadeRef);
          }}
        >
          Tailor Made
        </h2>
        <h2
          className={`${tab === "Escorted Tours" && "active"}`}
          onClick={() => {
            setTab("Escorted Tours");
            handleClick(escortedToursRef);
          }}
        >
          Escorted Tours
        </h2>
        <h2
          className={`${tab === "Luxury Collection" && "active"}`}
          onClick={() => {
            setTab("Luxury Collection");
            handleClick(luxuryCollectionRef);
          }}
        >
          Luxury Collection
        </h2>
        <h2
          className={`${tab === "Special Interest" && "active"}`}
          onClick={() => {
            setTab("Special Interest");
            handleClick(specialInterestRef);
          }}
        >
          Special Interest
        </h2>
        <h2
          className={`${tab === "Healing & Wellness" && "active"}`}
          onClick={() => {
            setTab("Healing & Wellness");
            handleClick(healingWellnessRef);
          }}
        >
          Healing & Wellness
        </h2>
        <h2
          className={`${tab === "Cruises" && "active"}`}
          onClick={() => {
            setTab("Cruises");
            handleClick(cruisesRef);
          }}
        >
          Cruises
        </h2>
        <h2
          className={`${tab === "Luxury Train" && "active"}`}
          onClick={() => {
            setTab("Luxury Train");
            handleClick(luxuryTrainRef);
          }}
        >
          Luxury Train
        </h2>
      </div>

      <div className="about-service">
        <p>
          Exotic India Vacations is a gateway to boundless travel experiences!
          Our mission is to transform every full-stop into a comma, opening up
          infinite possibilities for exploration and adventure. With the passion
          and dedication of our team, we excel in crafting personalized
          solutions for travelers and business partners alike, always aiming to
          surpass expectations.
        </p>
        <p>
          As a visionary organization, we are revolutionizing the realms of
          leisure travel. We offer exclusive experiences, exceptional service,
          and thrilling add-ons to ensure that every journey is not just a trip
          but a rich, rewarding adventure.
        </p>
        <p>
          Our goal is to infuse the ordinary with excitement, ensuring our
          guests experience far more than the typical and fully immerse
          themselves in the unique essence of each destination. We create
          memories that endure a lifetime through our bespoke services, strong
          local presence, exceptional value, authentic experiences, skilled
          on-ground team, and unwavering commitment to community welfare. With
          Exotic India Vacations, every journey becomes a story worth telling.
        </p>
      </div>

      {/* Tailor Made */}
      <div className="service row" ref={tailorMadeRef}>
        <div className="images one col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0"></div>
        <div className="cont col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="hrline">Tailor Made</h1>
          <p>
            At Exotic India Vacations, we understand that every traveler is
            unique, and that's why we specialize in creating tailor-made travel
            experiences that cater to individual preferences and interests. Our
            tailor-made journeys are crafted with meticulous attention to
            detail, ensuring that every aspect of your trip is designed to match
            your personal desires and requirements. From the moment you contact
            us, our team of experienced travel consultants will work closely
            with you to understand your travel goals, interests, and
            expectations. Whether you're seeking a cultural immersion in the
            heart of India, an adventurous trek in Nepal, a spiritual retreat in
            Bhutan, or a luxurious escape in Sri Lanka, we will design a
            personalized itinerary that perfectly aligns with your vision.
          </p>
        </div>
      </div>

      {/* Escorted Tours  */}
      <div className="service row" ref={escortedToursRef}>
        <div className="order-2 order-lg-1 cont col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="hrline">Escorted Trips</h1>
          <p>
            A thoughtfully crafted itinerary, complemented by an expert tour
            leader, turns even the simplest journeys into extraordinary
            adventures. Our team of skilled tour leaders, supported by a strong
            local network, rigorous quality control, and reliable supplier
            partnerships, consistently goes above and beyond at each
            destination. We invest in understanding our tour leaders' visions
            and provide them with comprehensive training to elevate the guest
            experience. Our seamless service delivery enhances all group travel
            programs across the Indian Subcontinent, supported by our inhouse
            tour managers, who are renowned for their deep expertise and
            engaging approach.
          </p>
        </div>
        <div className="order-1 order-lg-2 images two col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0"></div>
      </div>

      {/* Luxury Collection  */}
      <div className="service row" ref={luxuryCollectionRef}>
        <div className="images three col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0"></div>
        <div className="cont col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="hrline">Luxury Collection</h1>
          <p>
            We don’t merely envision exclusivity—we bring it to life with
            unparalleled precision. Our expert-led team is devoted to perfecting
            every detail of your guest’s luxury vacation. From securing the most
            opulent hotels and exclusive venues to curating one-of-a-kind
            experiences and offering attentive concierge services, we ensure
            every moment of their journey surpasses expectations. We are
            committed to delivering what we call a "True Maharaja or Maharani"
            experience. By understanding the sophisticated desires of luxury
            travelers, we craft the most exquisite and tailored travel plans,
            ensuring each getaway is nothing short of extraordinary.
          </p>
        </div>
      </div>

      {/* Special Interest  */}
      <div className="service row" ref={specialInterestRef}>
        <div className="order-2 order-lg-1 cont col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="hrline">Special Interest</h1>
          <p>
            We take pride in presenting a diverse collection of Exotic India
            Vacations' Special Interest Itineraries, each meticulously crafted
            to offer unique, theme-based travel experiences across the Indian
            Subcontinent. Whether you're seeking inspiration for groups or
            individuals, we're here to design and curate exceptional journeys.
            Our itineraries, enriched with authentic local experiences, are
            created by specialists with decades of expertise and a profound
            passion for their craft. This ensures that every journey not only
            meets but exceeds the expectations of today’s discerning travelers.
          </p>
        </div>
        <div className="order-1 order-lg-2 images four col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0"></div>
      </div>

      {/* Healing & Wellness  */}
      <div className="service row" ref={healingWellnessRef}>
        <div className="images five col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0"></div>
        <div className="cont col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="hrline">Healing & Wellness</h1>
          <p>
            From deep wellness retreats to a harmonious blend of ancient and
            modern healing practices, we provide enriching experiences aimed at
            transforming the mind, body, and soul. Whether it's indulging in a
            revitalizing spa treatment, savoring meticulously prepared healthy
            meals, engaging in meditation, exploring holistic healing,
            practicing Yoga, delving into Ayurveda, or embracing Naturopathy,
            every aspect is carefully designed for your well-being. Our
            longstanding relationships with top-tier suppliers ensure that
            guests enjoy the fullest benefits from their retreat, promising an
            unparalleled journey towards wellness.
          </p>
        </div>
      </div>

      {/* MICE  */}
      <div className="service row" ref={cruisesRef}>
        <div className="order-2 order-lg-1 cont col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="hrline">Cruises</h1>
          <p>
            Discover the uncharted beauty of India with River Expeditions. In
            collaboration with esteemed brands committed to authentic service
            and eco-friendly practices, we illuminate regional heritage while
            navigating two of India's most historic rivers—the Ganges and the
            Brahmaputra. Experience the rich tapestry of local cultures,
            histories, and wildlife up close, and explore otherwise inaccessible
            regions through our diverse range of itineraries. Our travel
            experts, with their extensive knowledge, are dedicated to assisting
            our partners in curating bespoke river cruise experiences for their
            guests. Additionally, we offer comprehensive expertise to serve as
            your provider for offshore excursions and pre/post tours for ocean
            cruises within the Indian subcontinent
          </p>
        </div>
        <div className="order-1 order-lg-2 images six col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0"></div>
      </div>

      {/* Cruises  */}
      <div className="service row" ref={luxuryTrainRef}>
        <div className="images seven col-12 col-sm-12 col-md-6 col-lg-6 p-0 m-0"></div>
        <div className="cont col-12 col-sm-12 col-md-6 col-lg-6">
          <h1 className="hrline">Luxury Train</h1>
          <p>
            Embark on an opulent journey across India with our luxury trains.
            Partnered with esteemed brands dedicated to authentic service and
            sustainable practices, we showcase India's rich heritage while
            traversing its diverse landscapes. From the regal Maharajas' Express
            to the iconic Palace on Wheels, our trains offer an unparalleled
            travel experience through India's historic cities and scenic vistas.
            Witness the grandeur of palaces, vibrant local cultures, and
            breathtaking natural beauty with meticulously curated itineraries.
            Our travel experts possess extensive knowledge to help our partners
            craft unforgettable rail journeys for their guests. Additionally, we
            offer comprehensive expertise to be your service provider for
            pre/post tours and bespoke excursions throughout the Indian
            subcontinent.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Service;
