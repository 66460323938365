import React, { useEffect, useState } from "react";
// import video2 from "../../video/video2.mp4";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";
import "./HomeVideos.css";

const HomeVideos = () => {
  const navigate = useNavigate();

  const [videos, setVideos] = useState([null, null, null, null]);

  const fetchVideos = async () => {
    try {
      const response = await axios.get("/api/videos/get-videos");
      setVideos(response.data);
    } catch (error) {
      message.error("Failed to fetch videos");
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <div className="home-videos text-center">
      <div className="cursive">
        Our <span className="cursive">Experiences</span>
      </div>
      <span className="text-white">Discover our videos to learn more</span>
      <div className="h-videos row">
        {videos.map((item, index) => {
          return (
            <div
              key={index}
              className="youtube-videos col-12 col-sm-12 col-md-6 col-lg-6"
            >
              <iframe
                // width="560"
                // height="315"
                src={item}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          );
        })}
      </div>
      <button
        onClick={() => navigate("/experiences")}
        className="theme-btn-2 mt-5"
      >
        Know More
      </button>
    </div>
  );
};

export default HomeVideos;
