import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Slider from "react-slick";
import axios from "axios";
import { message } from "antd";
import "./Testimonials.css";

const Testimonials = () => {
  const arrowRef = useRef();

  const [testimonials, setTestimonials] = useState([]);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get("api/testimonials/get-testimonials");
      setTestimonials(response.data);
    } catch (error) {
      message.error("Failed to fetch testimonials");
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  var settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: false,
        },
      },
      {
        breakpoint: 400,
        settings: {
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="testimonial-container text-center">
      <div className="testimonial">
        <h1>Our Testimonials</h1>
        <Slider ref={arrowRef} {...settings}>
          {testimonials?.map((item, index) => {
            return (
              <div key={index} className="review">
                <p>{item?.review}</p>
                <h5>{item?.name}</h5>
                <h6>{item?.location}</h6>
              </div>
            );
          })}
        </Slider>
        <div className="testimonial-slider-btn">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon test-slider-left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon test-slider-right" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
